import {bindable, customElement, inject} from 'aurelia-framework';
import {FormServiceFactory} from '../form/service/form-service-factory';
import * as _ from 'lodash';
import {EventAggregator} from "aurelia-event-aggregator";
import {Client} from "../api/client";
import {countryChoices} from "../order/paticipants-list/static/choiceOptions";

@customElement('address-input')
@inject(FormServiceFactory, EventAggregator, Client)
export class AddressInput {
    //Todo map errors of subfields

    apiKey;
    updated = false;
    config = {
        template: '',
        fields: [
            {
                type: 'choice',
                set: 'addressType',
                property: 'addressType',
                label: 'sio.field.addressType',
            },
            {
                type: 'text',
                property: 'addressLine1',
                label: 'sio.field.addressLine1',
                required: true
            },
            {
                type: 'text',
                property: 'addressLine2',
                label: 'sio.field.addressLine2',
            },
            {
                type: 'text',
                property: 'zip',
                label: 'sio.field.zip'
            },
            {
                type: 'text',
                property: 'city',
                label: 'sio.field.city',
                required: true
            },
            {
                type: 'text',
                property: 'region',
                label: 'sio.field.region',
            },
            {
                type: 'text',
                property: 'poBox',
                label: 'sio.field.poBox',
                required: true
            },
            {
                type: 'text',
                property: 'poBoxZip',
                label: 'sio.field.poBoxZip',
            },
            {
                type: 'text',
                property: 'poBoxLocation',
                label: 'sio.field.poBoxLocation',
            },
            {
                type: 'choice',
                set: 'country',
                property: 'country',
                label: 'sio.field.country',
                required: true,
                // default: 'DE'
            },
            {
                type: 'hash',
                property: 'coordinates'
            },
            {
                type: 'text',
                property: 'placeId'
            },
            {
                type: 'text',
                property: 'formattedAddress',
                label: 'sio.field.formattedAddress',
                required: false
            },
        ]
    };

    personFields = [
        {
            type: 'choice',
            set: 'salutation',
            property: 'salutation',
            label: 'sio.field.salutation',
        },
        {
            type: 'choice',
            set: 'title',
            property: 'title',
            label: 'sio.field.title',
        },
        {
            type: 'text',
            property: 'firstName',
            label: 'sio.field.firstName',
        },
        {
            type: 'text',
            property: 'lastName',
            label: 'sio.field.lastName',
        },
        {
            type: 'text',
            property: 'company',
            label: 'sio.field.company',
        },
    ];

    constructor(formServiceFactory, eventAggregator, client) {
        this.formServiceFactory = formServiceFactory;
        this.ea = eventAggregator;
        this.client = client;
    }

    @bindable field;
    @bindable person = false;
    @bindable({defaultBindingMode: 2}) value;

    async filteredCountriesChoiceSet(selectedCountryCodes) {
        const countrySet = await countryChoices(this.client);

        if (selectedCountryCodes.length === 0) {
            return countrySet;
        }

        let filteredChoices = [];

        countrySet.forEach((country) => {
            if (selectedCountryCodes.includes(country.value)) {
                filteredChoices.push(country);
            }
        });

        return filteredChoices;
    }

    async bind() {
        this._config = _.cloneDeep(this.config);

        this._config.fields[0].hidden = this.field.options?.hideAddressType ?? false;

        let countries = this.field.options?.countries ?? [];

        this._config.fields[9].choices = this.filteredCountriesChoiceSet(countries);
        this._config.fields[9].set = null;

        if (this.person) {
            this._config.fields = this._config.fields.concat(this.personFields);
        }

        if (this.field.singleLine) {
            this._config.fields[1].required = false;
            this._config.fields[4].required = false;
            this._config.fields[9].required = false;
            this._config.fields[12].required = true;
        }

        if (!this.field.required) {
            this._config.fields[1].required = false;
            this._config.fields[4].required = false;
            this._config.fields[9].required = false;
        }

        if (this.formService) {
            this.formService.changeCallback = null;
        }

        this._config.fields[9].default = await this.fetchDefaultCountry();

        this.formService = this.formServiceFactory.getFormService(this._config, _.cloneDeep(this.value));

        this.field.setValue(this._transformValue(this.formService.getValue()));

        this.formService.changeCallback = this.formValueChanged.bind(this);

        this.fieldChanged();
    }

    async fetchDefaultCountry() {
        let response;
        try {
            response = await this.client.get('instance-setting/default-country', true);
        } catch (throwable) {
            // do not nothing, backward compatibility;
        }
        return response ?? 'DE';
    }

    _getControlUID(config) {
        return config.modelId + '/' + config.fullProperty;
    }

    valueChanged() {
        this.formService.setValue(this._transformValue(this.value));
    }

    fieldChanged() {
        if (this.field) {
            this.field.onPostError = this.onPostError.bind(this);
        }
    }

    onPostError(field) {
        this.formService.setErrors({children: field.childErrors});
    }

    _transformValue(value)
    {
        let empty = true;

        _.each(value, (sub, key) => {
            if (key == 'addressIdentifier') {
                return false;
            }
            if (sub && sub != '') {
                empty = false;
            }
        });

        if (empty && !value?.coordinates?.lat && !value?.coordinates?.lng) {
            return null;
        } else {
            return value;
        }
    }

    formValueChanged(newValue) {
        let value = this.formService.getValue();

        if (value && value.addressLine1 && value.city && value.country && window.google) {

            let property = newValue.fullProperty ?? null;
            let notInProperty = this.personFields.map(property => property.property);

            if (!notInProperty.includes(property) && newValue.value) {
                this.updated = true;
                this.value.coordinates = {
                    lat: null,
                    lng: null,
                    update: true,
                }
            }
        }

        this.value = this._transformValue(value);

    }


    publishGeocodingEvent() {
        this.ea.publish('sio_address.geocode.update.coordinates', {
            type: 'address',
            value: this.value,
            uuid: this._getControlUID(this.field)
        })
        this.updated = false;
    }

    doNotUpdate() {
        this.updated = false;
    }


}
